import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Link,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import {
  MailRegular,
  PersonAddRegular,
  PhoneRegular,
} from "@fluentui/react-icons";

import { ContactSearchItem } from "@aglocal/schema/ContactSearchResult";
import { Heading, Text } from "@aglocal/web/components/Typography";
import Stack from "@aglocal/web/components/Stack";
import titleCase from "@aglocal/web/helpers/titleCase";
// import Card from "@aglocal/web/components/Card";

const useStyles = makeStyles({
  container: {
    marginBottom: tokens.spacingVerticalM,
  },
  heading: {
    ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalNone),
  },
  row: {
    ...shorthands.margin(tokens.spacingHorizontalNone),
  },
  selectButton: {
    ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalNone),
  },
  icon: {
    verticalAlign: "-0.125em",
  },
});

export interface ContactDetailsProps {
  contact: ContactSearchItem;
  disabled: boolean;
  onSelect: (contact: ContactSearchItem) => void;
}

export default function ContactDetails({
  contact,
  disabled,
  onSelect,
}: ContactDetailsProps) {
  const styles = useStyles();
  return (
    <Card className={styles.container}>
      <CardHeader
        header={
          <Heading as="h4" variant="subtitle2" className={styles.heading}>
            <Link
              as="a"
              href={`https://allegisgroup.lightning.force.com/lightning/r/Contact/${contact.id}/view`}
              target="_blank"
              rel="noopener noreferrer"
              title="View in Connected"
            >
              {contact.name}
            </Link>
          </Heading>
        }
        description={
          <Stack gap="xs">
            <Text className={styles.row}>
              {titleCase(contact.title) || "Unknown"}
            </Text>
            <Text className={styles.row}>
              <MailRegular className={styles.icon} />{" "}
              {contact.email || "Unknown"}
            </Text>
            <Text className={styles.row}>
              <PhoneRegular className={styles.icon} />{" "}
              {contact.phone || "Unknown"}
            </Text>
          </Stack>
        }
      />
      <CardFooter>
        <Button
          className={styles.selectButton}
          icon={<PersonAddRegular />}
          disabled={disabled}
          onClick={() => {
            onSelect(contact);
          }}
        >
          Select
        </Button>
      </CardFooter>
    </Card>
  );
}
