import useReportError from "@aglocal/web/hooks/useReportError";

import { useDocSnapshot, useMissionControlDB } from "@aglocal/web/firebase";

import ErrorPage from "@/pages/Error";
import LoadingPage from "@/pages/Loading";

import OutsideMeetingTab from "./OutsideMeetingTab";
import PostMeetingTab from "./PostMeetingTab";
import ContactSelector from "./ContactSelector";

export interface FullPageAppProps {
  iid: string;
}

export default function FullPageApp({ iid }: FullPageAppProps) {
  const db = useMissionControlDB();
  const { snapshot, loading, error } = useDocSnapshot(db.interviews.doc(iid));

  useReportError(error);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage title="Error loading meeting" />;
  }

  const interview = snapshot?.data();
  if (!interview) {
    return <ErrorPage title="Meeting not found" />;
  }

  if (interview.interviewee?.connectedDetails == null) {
    return <ContactSelector interview={interview} />;
  }

  switch (interview.meetingStatus) {
    case "Complete":
      return <PostMeetingTab interview={interview} />;
    default:
      return <OutsideMeetingTab interview={interview} />;
  }
}
