import { useState } from "react";
import { logEvent } from "firebase/analytics";

import {
  Link,
  makeStyles,
  mergeClasses,
  Spinner,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  tokens,
  useId,
  useToastController,
} from "@fluentui/react-components";

import type { Interview } from "@aglocal/schema/Interview";

import { useMissionControl } from "@aglocal/web/mission-control";

import Container from "@aglocal/web/components/Container";
import { Heading } from "@aglocal/web/components/Typography";

import {
  useDoc,
  useFirebaseAnalytics,
  useMissionControlDB,
} from "@aglocal/web/firebase";
import useDarkquell from "@aglocal/web/firebase/queries/useDarkquell";
import useLLMNER from "@aglocal/web/firebase/queries/useLLMNER";

import asError from "@aglocal/web/helpers/asError";
import useMatchMedia from "@aglocal/web/hooks/useMatchMedia";
import useReportError from "@aglocal/web/hooks/useReportError";

import Stack from "@aglocal/web/components/Stack";

import useSetDefault from "@/hooks/useSetDefault";

import ErrorPage from "@/pages/Error";
import LoadingPage from "@/pages/Loading";

import {
  getConnectedDetailsUpdate,
  usePostMeetingFormWithSummary,
} from "./form";
import PostMeetingFooter from "./PostMeetingFooter";
import PostMeetingDrawer from "./PostMeetingDrawer";

import CompanyField from "./fields/CompanyField";
import DetailsField from "./fields/DetailsField";
import GoalsAndInterestsField from "./fields/GoalsAndInterestsField";
import LocationCommentsField from "./fields/LocationCommentsField";
import NotesField from "./fields/NotesField";
import SkillsCommentsField from "./fields/SkillsCommentsField";
import SkillsField from "./fields/SkillsField";
import SummaryField from "./fields/SummaryField";
import TitleField from "./fields/TitleField";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "grid",
    overflow: "hidden",
    gridTemplateAreas: `
        "header"
        "main"
        "footer"
      `,
    gridTemplateRows: "auto minmax(0, 1fr) auto",
  },
  header: {
    overflow: "auto",
    gridArea: "header",
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  content: {
    gridArea: "main",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
  },
  main: {
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    flexGrow: 1,
    flexBasis: 0,
  },
  container: {
    maxWidth: "720px",
    rowGap: tokens.spacingVerticalM,
    columnGap: tokens.spacingHorizontalM,
    marginTop: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
  },
  section: {
    flexGrow: 1,
    flexBasis: "24rem",
  },
  footer: {
    gridArea: "footer",
  },
});

function ProgressToast() {
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        Updating Connected...
      </ToastTitle>
    </Toast>
  );
}

function DismissibleToast({ message }: { message: string }) {
  return (
    <Toast>
      <ToastTitle
        action={
          <ToastTrigger>
            <Link as="button">Dismiss</Link>
          </ToastTrigger>
        }
      >
        {message}
      </ToastTitle>
    </Toast>
  );
}

export interface PostMeetingTabProps {
  interview: Interview;
}

export default function PostMeetingTab({ interview }: PostMeetingTabProps) {
  const styles = useStyles();

  const api = useMissionControl();
  const db = useMissionControlDB();
  const analytics = useFirebaseAnalytics();

  const submitToastId = useId("toast-submit-");
  const toasterId = useId("toaster-");
  const { dispatchToast, updateToast } = useToastController(toasterId);

  const isXL = useMatchMedia({ min: "xl" });
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean | null>(null);

  const {
    control,
    getFieldState,
    getValues,
    handleSubmit,
    resetField,
    setError,
    setValue,
  } = usePostMeetingFormWithSummary(interview);

  // TODO: pull down current connected details via api endpoint

  const setDefault = useSetDefault({
    getFieldState,
    getValues,
    resetField,
    setValue,
  });

  const [skills, darkquellLoading, darkquellError] = useDarkquell(interview.id);
  const [entities, llmNerLoading, llmNerError] = useLLMNER(interview.id);
  const [details, detailsLoading, detailsError] = useDoc(
    db.talentMeetingDetails.doc(interview.id),
  );

  useReportError(darkquellError);
  useReportError(llmNerError);
  useReportError(detailsError);

  const loading = darkquellLoading || llmNerLoading || detailsLoading;
  const error = darkquellError ?? llmNerError ?? detailsError;

  if (interview.interviewee?.connectedDetails == null) {
    // We should never reach hear instead of the Contact Selector Page
    return <ErrorPage title="Connected Details Not Found" />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage title="Error loading meeting" />;
  }

  const onSubmit = handleSubmit(async (data) => {
    if (data.notes.trim() === "" && data.summary.value.trim() === "") {
      const error = {
        type: "required",
        message: "Please enter a meeting summary or notes",
      };

      setError("notes", error);
      setError("summary", error);

      return;
    }

    try {
      logEvent(analytics, "meeting_summary_submitted", {
        iid: interview.id,
        name: data.summary.name,
        settings: data.summary.settings,
        edited: getFieldState("summary.value").isDirty,
      });
    } catch (error) {
      reportError(error);
    }

    dispatchToast(<ProgressToast />, {
      toastId: submitToastId,
      timeout: -1,
      position: "bottom",
    });

    try {
      const update = getConnectedDetailsUpdate(data);
      await api.updateConnectedDetails(update);
      updateToast({
        content: <DismissibleToast message="Connected Updated" />,
        toastId: submitToastId,
        intent: "success",
      });
    } catch (error: unknown) {
      updateToast({
        content: <DismissibleToast message="Error Updating Connected" />,
        toastId: submitToastId,
        intent: "error",
      });
      reportError(asError(error));
    }
  });

  return (
    <form
      className={mergeClasses(styles.root)}
      onSubmit={(event) => {
        void onSubmit(event);
      }}
    >
      <header className={styles.header}>
        <Container>
          <Heading variant="subtitle1">
            Post meeting conversation
            {interview.interviewee.connectedDetails.displayName &&
              ` with ${interview.interviewee.connectedDetails.displayName}`}
          </Heading>
        </Container>
      </header>

      <div className={styles.content}>
        <main className={styles.main}>
          <Container className={styles.container}>
            <Stack className={styles.section} gap="m">
              <TitleField
                interview={interview}
                entities={entities}
                details={details}
                control={control}
                setDefault={setDefault}
              />
              <CompanyField
                details={details}
                control={control}
                setDefault={setDefault}
              />
              {/* Date Available */}
              <NotesField control={control} />
              <SummaryField
                interview={interview}
                control={control}
                getFieldState={getFieldState}
                getValues={getValues}
                resetField={resetField}
                setValue={setValue}
              />
              <SkillsField
                existingSkills={interview.interviewee.connectedDetails.skills}
                suggestedSkills={details?.skills}
                control={control}
                setDefault={setDefault}
              />
              <SkillsCommentsField
                details={details}
                control={control}
                setDefault={setDefault}
              />
              <GoalsAndInterestsField
                details={details}
                control={control}
                setDefault={setDefault}
              />
              <LocationCommentsField
                details={details}
                control={control}
                setDefault={setDefault}
              />
              <DetailsField
                details={details}
                control={control}
                setDefault={setDefault}
              />
            </Stack>
          </Container>
        </main>
        <PostMeetingDrawer
          isOpen={drawerIsOpen ?? isXL}
          onOpenChange={setDrawerIsOpen}
          interview={interview}
          skills={skills}
          entities={entities}
        />
      </div>

      <PostMeetingFooter
        className={styles.footer}
        control={control}
        drawerIsOpen={drawerIsOpen ?? isXL}
        setDrawerIsOpen={setDrawerIsOpen}
      />

      <Toaster toasterId={toasterId} />
    </form>
  );
}
