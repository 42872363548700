import { Spinner, makeStyles } from "@fluentui/react-components";

import TeamsBackground from "@/components/TeamsBackground";

const useStyles = makeStyles({
  spinner: {
    marginTop: "calc(50vh - 32px)",
  },
});

export default function LoadingPage() {
  const styles = useStyles();
  return (
    <TeamsBackground>
      <Spinner className={styles.spinner} />
    </TeamsBackground>
  );
}
