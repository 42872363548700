import { Schema } from "@effect/schema";
export const ContactSearchItem = Schema.Struct({
    id: Schema.String,
    accountId: Schema.String,
    name: Schema.String,
    title: Schema.String,
    email: Schema.String,
    phone: Schema.String,
    talentId: Schema.String,
    lastActivityDate: Schema.String,
});
export const ContactSearchResult = Schema.Struct({
    contacts: Schema.Array(ContactSearchItem),
    queryType: Schema.String,
});
export const ContactSearchRequest = Schema.Struct({
    iId: Schema.String,
    searchTerm: Schema.String,
});
export const ContactSelectRequest = Schema.Struct({
    iId: Schema.String,
    contactId: Schema.String,
});
