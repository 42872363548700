// Roles set on Users via Entra Enterprise Application - which we manage in the application
// To add a new role, add it to managedRoleTypes and add a comment below on what it is for, then get the exact same string added
// to Entra App registration for the MissionControl app. Users/Groups can then assigned that role.
export var AuthBrands;
(function (AuthBrands) {
    AuthBrands["All"] = "allbrands";
    AuthBrands["Aerotek"] = "aer";
    AuthBrands["TEKsystems"] = "tek";
    AuthBrands["AstonCarter"] = "ast";
    AuthBrands["ActalentServices"] = "act";
})(AuthBrands || (AuthBrands = {}));
export const managedRoleTypes = [
    "int.access", //  int.access allows access to all the interview objects in Firestore.
    "admin.access" // admin.access allows access to the TA Admin site & all the interview objects in Firestore.
];
