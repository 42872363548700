import { ReactNode } from "react";

import { useTeams } from "@/teams";

import LoadingPage from "@/pages/Loading";

import MsalAuthProvider from "./MsalAuthProvider";
import TeamsAuthProvider from "./TeamsAuthProvider";

export interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const teams = useTeams();

  if (teams.loading) {
    return <LoadingPage />;
  }

  if (teams.inTeams) {
    return (
      <TeamsAuthProvider context={teams.context}>{children}</TeamsAuthProvider>
    );
  }

  return <MsalAuthProvider>{children}</MsalAuthProvider>;
}
