import { useCallback } from "react";
import asError from "@aglocal/web/helpers/asError";
import useSafeState from "./useSafeState";
export default function useError(initialValue = null) {
    const [error, setError] = useSafeState(() => initialValue === null ? null : asError(initialValue));
    const setUnknownError = useCallback((value) => {
        setError(asError(value));
    }, [setError]);
    const clearError = useCallback(() => {
        setError(null);
    }, [setError]);
    return [error, setUnknownError, clearError];
}
